import { supabase } from "./supabase";

import { FunctionsHttpError, FunctionsRelayError, FunctionsFetchError } from '@supabase/supabase-js';

export const fetchRecords = async (table, joins = []) => {
  var query = '*';

  if (joins.length > 0) {
     query = `*,${joins.map(join => `${join.table}(${join.fields})`).join(', ')}`
  }
  let qr = supabase.from(table).select(query);
  const { data, error } = await qr;
  console.log('fetchRecords',data,error)
  if (error) throw error;

  // Transform data to handle translations
  const transformedData = data.map(item => {
    const newItem = { ...item };
    const translationSuffix = /_translations?$/;
    for (const key in newItem) {
      if (translationSuffix.test(key)) {
        newItem.translations = newItem[key];
        delete newItem[key];
      }
    }
    return newItem;
  });

  // console.log('transformedData',transformedData)
  return transformedData;
};

export const addRecord = async (table, record) => {
  console.log('addRecord',table,record)
  const { data, error } = await supabase.from(table).insert(record).single();
  if (error) throw error;
  return data;
};

export const updateRecord = async (table, id, updates, primaryKey = 'id') => {

console.log('updateRecord',  updates);
if (!updates) return null; // Handle null updates gracefully

// different table different primary key
if (table === 'users_profile') {
  primaryKey = 'id';  
}else if(table === 'events') {
  delete updates['publish_period'];
  delete updates['registration_period'];
  primaryKey = 'id';  
}else if(table === 'event_registrations') {
  delete updates['publish_period'];
  delete updates['registration_period'];
  primaryKey = 'id';  
}

const query = supabase.from(table).update(updates).eq(primaryKey, id);
console.log('Generated query:', query);  // Debugging query

const { data, error } = await query;

// Log both data and error
console.log('Supabase response:', { data, error });

if (error) {
  console.error('Error during update:', error);
  throw error;
}
return data

};


export const deleteRecord = async (table, id, primaryKey = 'id') => {
  console.log('deleteRecord',table,primaryKey,id)
  const { data, error } = await supabase.from(table).delete().eq(primaryKey, id);
  if (error) throw error;
  return data;
};

export const uploadImageAndGetPublicUrl = async (folder, file) => {
  try {
    const filePath = file.name
      .trim()
      .toLowerCase()                 // Convert to lowercase (optional)
      .replace(/\s+/g, '_')         // Replace spaces with underscore
      .replace(/[^\w.-]/g, '')      // Remove special characters
      .replace(/_{2,}/g, '_');    

    // const filePath = `${file.name}`;
    const bucket = `${folder}`;
    const { data, error: uploadError } = await supabase.storage
      .from(bucket)  
      .upload(filePath, file, {
        upsert: true
      });

    if (uploadError) {
      throw uploadError;
    }

    console.log('File uploaded successfully:', data);

    const { data: urlData, error: urlError } = supabase.storage
      .from(bucket)
      .getPublicUrl(filePath);

    if (urlError) {
      throw urlError;
    }

    const publicUrl = urlData.publicUrl
    console.log('Public URL:', publicUrl);
    return publicUrl;

  } catch (error) {
    console.error('Error:', error.message);
   
  }
};


export const fetchRecordsNew = async (table, options = {}) => {
  const {
    joins = [],
    filterConfig = {},
    sortConfig = {},
    pagination = {},
    searchFields = []
  } = options;

  try {
    let query = supabase.from(table);

    let select = '*';
    if (joins.length > 0) {
      select = `*,${joins.map(join => `${join.table}(${join.fields})`).join(', ')}`
    }
    
    let dataQuery = query.select(select);
    let countQuery = query.select(select, { count: 'exact'});
    // Apply search
    if (filterConfig.text && searchFields.length > 0) {
      const searchConditions = searchFields.map(field => `${field}.ilike.%${filterConfig.text}%`).join(',');
      dataQuery = dataQuery.or(searchConditions);
      countQuery = countQuery.or(searchConditions);
    }
    
    // Apply filter
    if (filterConfig.field && filterConfig.value) {
      const dateFields = ['created_at', 'updated_at', 'date_of_birth'];

      if (dateFields.includes(filterConfig.field)) {
        // Handle date fields
        const date = new Date(filterConfig.value);
        const startOfDay = new Date(date.setHours(0, 0, 0, 0)).toISOString();
        const endOfDay = new Date().toISOString();

        dataQuery = dataQuery.gte(filterConfig.field, startOfDay).lt(filterConfig.field, endOfDay);
        countQuery = countQuery.gte(filterConfig.field, startOfDay).lt(filterConfig.field, endOfDay);
      
      } else {
        // For non-date fields, use ilike as before
        dataQuery = dataQuery.ilike(filterConfig.field, `%${filterConfig.value}%`);
        countQuery = countQuery.ilike(filterConfig.field, `%${filterConfig.value}%`);
      }
    }

    // Apply sort
    if (sortConfig.field) {
      if (sortConfig.field.includes('.')) {
        // Handle translation field sorting
        const [table, field] = sortConfig.field.split('.');
        // console.log('sortConfig.field.includes ascending', table, field, sortConfig.direction)
        dataQuery = dataQuery.order(field, {
          foreignTable: table,
          ascending: sortConfig.direction === 'asc'
        });

      } else {
        // Regular field sorting
        dataQuery = dataQuery.order(sortConfig.field, {
          ascending: sortConfig.direction === 'asc'
        });
      }
    }

    // Apply pagination
    if (pagination.currentPage && pagination.itemsPerPage) {
      const from = (pagination.currentPage - 1) * pagination.itemsPerPage;
      const to = from + pagination.itemsPerPage - 1;
      if (from > to || from < 0) {
        console.error('Pagination range error:', { from, to });
        throw new Error('Invalid pagination range.');
      }
      dataQuery = dataQuery.range(from, to);
    }

    const { data, error} = await dataQuery;
    const { count, error: countError } = await countQuery;

    if (error) throw error;
    if (countError) throw countError;
    // Transform data to handle translations
    const transformedData = data.map(item => {
      const newItem = { ...item };
      const translationSuffix = /_translations?$/;
      
      // Handle translations
      for (const key in newItem) {
        if (translationSuffix.test(key)) {
          // Move translation data to a translations property
          newItem.translations = newItem[key];
          delete newItem[key];
        }
      }

      return newItem;
    });

    console.log('fetchRecordsNew', transformedData, count);
    return { data: transformedData, count };

  } catch (error) {
    console.log("error=====", error);
    let message = 'An error occurred while fetching records.';

    if (error instanceof FunctionsHttpError) {
      const errorContext = await error.context.json();
      message = errorContext.error.message;
    } else if (error instanceof FunctionsRelayError) {
      message = error.message;
    } else if (error instanceof FunctionsFetchError) {
      message = error.message;
    }

    console.error('fetchRecords error:', message);
    throw new Error(message);
  }
};

export const updateForceRecord = async (record) => {
  const { id, mandatory_update_version, platform } = record;
  // console.log('updateForceRecord',record, id)
  let { data, error } = await supabase
    .rpc('update_force_update', {
      id: id || null, 
      mandatory_update_version, 
      platform
    })
  if (error)  {
    console.error(error);
    throw error; 
  }
    
  return data;

}


export const rpcRecord = async (rpc, updates) => {
  // console.log('rpcRecord',rpc,updates)
  // if (!updates) return null; // Handle null updates gracefully

  // `%${filterConfig.value}%`)
   // Convert updates object to JSON string
  let { data, error } = await supabase
    .rpc(rpc, updates)
  if (error) throw error;
  return data;
};

export const fetchMemberPointsHistory = async (memberId) => {
  try {
    // 使用 member_id 字段进行匹配
    const { data, error } = await supabase
      .from('member_points_history')
      .select('*')
      .eq('member_id', memberId) // 确保使用正确的字段名
      .order('created_at', { ascending: false });

    if (error) {
      console.error('获取积分历史记录错误:', error);
      throw error;
    }

    return data || []; // 确保返回空数组而不是 null
  } catch (error) {
    console.error('查询积分历史记录失败:', error.message);
    throw error;
  }
};