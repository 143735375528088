import React, { useState , useEffect } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";

import BaseMemberActivityPageComponent from '../../components/base/BaseMemberActivityPageComponent'
import { fetchMemberPointsHistory } from '../../../../api/genericApi';
import InlineEditBox from '../../components/base/InlineEditBox';
const ModelTable = ({ isOpen, onClose, data }) => {

  const tableName = 'member_points_history';
  const primaryKey = 'id';

  const keyMappings = {
    member_id: 'Member ID',
    transaction_date: 'Transaction Date',
    transaction_type: 'Transaction Type',
    amount: 'Amount',
    points: 'Points',
    points_before: 'Points Before',
    points_after: 'Points After', 
    description: 'Description',
    coupon_id: 'Coupon ID',
    created_at: 'Created At',
    updated_at: 'Updated At',
    is_addition: 'Is Addition'
  };

  const tableHeaders = [
    'member_id',
    'transaction_date',
    'transaction_type', 
    'amount',
    'points',
    'points_before',
    'points_after',
    'description',
    'coupon_id',
    'created_at',
    'updated_at',
    'is_addition'
  ];
  return (
    <Box p={5}>
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent maxWidth="1000px">
          <DrawerCloseButton />
          <DrawerHeader>详情</DrawerHeader>
          <DrawerBody>
          <BaseMemberActivityPageComponent
          txt="Member Info"
          showToggleView={false}
          tableName={tableName}
          tableHeaders={tableHeaders}
          keyMappings={keyMappings}
          showPagination={true}
          showSearch={false}
          searchPlaceholder="Search by ID/Member ID"
          searchFields="email"
          primaryKey = {primaryKey}
          showAddIcon={false}
          showAddIcon={false}
          showActions={false}
          tempData={data}
        />
          </DrawerBody>
          <DrawerFooter>
            <Button colorScheme="blue" onClick={onClose}>
              关闭
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default ModelTable;
