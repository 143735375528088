import React, { useState } from 'react';
import BaseMemberActivityPageComponent from '../../components/base/BaseMemberActivityPageComponent'
import AddEditNewsModal from './AddEditNewsModal';
import NewsCard from './NewsCard';

const NewsPage = () => {
  const [language, setLanguage] = useState('en'); 
  const tableName = 'users_profile';
  const primaryKey = 'id';

  const keyMappings = {
    ID: 'ID',
    surname: 'Title',
    email: 'Image',
    phone: 'Game',
    birthday: 'Play Type',
    gender: 'Prize',
    points:'Publish Period',
    
    // id: 'ID',
    // title: 'Title',
    // image: 'Image',
    // Game: 'Game',
    // play_type: 'Play Type',
    // prize: 'Prize',
    // publish_start: 'Publish Date',
    // publish_end: 'Publish Date',
    // publish_period: 'Publish Period',
    // event_start: 'Event Start Time',
    // event_end: 'Event Start Time',
    // event_period: 'Event Period',
    //status: 'Status',
  };

  const tableHeaders = [
    'given_name',
    'surname',
    'email',
    'phone',
    'birthday',
    'gender',
    'points',
    // is_active: 'Active',
    // created_at: 'Created at',
    // updated_at: 'Updated at',
    // deleted_at: 'Deleted at',
  ];

  return (
    <BaseMemberActivityPageComponent
      txt="Member Info"
      addItemComponent={AddEditNewsModal}
      itemCardComponent={NewsCard}
      showToggleView={false}
      tableName={tableName}
      tableHeaders={tableHeaders}
      keyMappings={keyMappings} 
      showPagination={true}
    //   showSearch={true}
    //   searchPlaceholder="Search by email"
    //   searchFields="email"
      showAddIcon={true}
      isViewDisabled={false}
      primaryKey={primaryKey}
      hideEdit={true}
      hideDelete={true}
    />
  );
};

export default NewsPage;

