import React, { useState } from 'react';
import BasePageComponent from '../../components/base/BasePageComponent'
import AddEditNewsModal from './AddEditNewsModal';
import NewsCard from './NewsCard';
import ModalTable from './ModalTable'; 
const NewsPage = () => {
  const [language, setLanguage] = useState('en');
  const [isModalTableOpen,setIsModalTableOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const tableName = 'users_profile';
  const primaryKey = 'id';

  const keyMappings = {
    given_name: 'Given Name',
    surname: 'Surname',
    email: 'Email',
    phone: 'Phone',
    birthday: 'Birthday',
    gender: 'Gender',
    points: 'Points',
  };

  const tableHeaders = [
    'given_name',
    'surname',
    'email',
    'phone',
    'birthday',
    'gender',
    'points',
  ];

  const handleView = (item) =>{
    setSelectedRow(item);
    setIsModalTableOpen(true);
  }

  const handleCloseModelTable = () =>{
    setSelectedRow(null);
    setIsModalTableOpen(false);
  }

  return (
    <>
      <BasePageComponent
        txt="Member Info"
        addItemComponent={AddEditNewsModal}
        itemCardComponent={NewsCard}
        showToggleView={false}
        tableName={tableName}
        tableHeaders={tableHeaders}
        keyMappings={keyMappings}
        showPagination={true}
        showSearch={true}
        searchPlaceholder="Search by email"
        searchFields="email"
        showAddIcon={false}
        primaryKey={primaryKey}
        hideView = {false}
        onView={handleView}
      />

      <ModalTable
        isOpen={isModalTableOpen}
        onClose={handleCloseModelTable}
        data={selectedRow} 
      />
    </>
  );
};

export default NewsPage;

